.plan-item {
    --color: #98BEF7
}

.plan-item:nth-child(2n) {
    --color: #F6837B
}
.plan-item + .plan-item {
    margin-top: 1.75rem;
}
.plan-item > h2 {
    padding: 0.75rem 1.5rem;
    background-color: var(--color);
    display: inline-block;
    border-radius: 0.75rem;
    margin-left: 2.5rem;
    transform: rotate(-2deg);
    margin-bottom: -2rem;
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
}

.plan-item > .content  {
    background: rgba(245, 245, 245, 0.5);
    backdrop-filter: blur(15px);
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    display: flex;
    flex-wrap: wrap;
}

.plan-item > .content > .description {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    padding: 1.5rem 3rem;

}

.plan-description {
    min-width: 250px;
    flex: 1
}
.plan-description >h3 {
    margin-bottom: 0.75rem;
}

.plan-description.result {
    background-color: var(--color);
    /*margin: -2.8rem -2.6rem -2.1rem;*/
    margin: 0.4rem;
    padding: 1.5rem 2.5rem;
    border-radius: 0.35rem;
    box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
}


/*-----------------------------------------------------------------------------------*/
button {
    padding: 0.75rem 1.5rem;
    border: unset;
    margin: 2.5rem auto 0;
    display: block;
    border-radius: 0.75rem;
    font-size: 1.5rem;
    font-weight: bold;
    background: rgba(245, 245, 245, 0.5);
    backdrop-filter: blur(15px);
}

div.body {
    background: linear-gradient(180deg, rgba(152, 190, 247, 0) 0%, rgba(246, 131, 123, 0.39) 100%);
    padding-bottom: 2.5rem
}
/*-----------------------------------------------------------------------------------*/
